


























import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import AbpBase from '@/lib/abpbase'
import util from '@/lib/util';

@Component({
    components: {},
})
export default class History extends AbpBase {

    get list() {
        return this.$store.state.courseclass.history;
    }
    get loading() {
        return this.$store.state.courseclass.hisLoading;
    }

    state(id) {
        return util.getTypeValueById(id);
    }

    created() {

    }

    mounted() {

    }
}