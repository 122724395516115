







































































































































































import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import AbpBase from '@/lib/abpbase'
import Util from '@/lib/util';
import url from '@/lib/url'
import StudentForm from '@/views/students/student/student-form.vue';

@Component({
    components: { StudentForm },
})
export default class EenrollmentsList extends AbpBase {
    menu: boolean = false;
    menus: any = {};
    reject_message: any = null;
    search: string = '';
    currentItem: any = null;
    showStuModal: boolean = false;
    sState: any = Util.abp.list.getCodeList('E_CLASS');

    columns = [
        { value: 'student', text: 'Asistente', type: 'text', sortable: false },
        { value: 'amount', text: 'Monto', type: 'text', sortable: false },
        { value: 'ranking', text: 'Calificacion', type: 'text', sortable: false },
        //{ value: 'created_at', text: 'Fecha de Matricula', type: 'text', sortable: false },
        { value: 'document_number', text: 'Boleta de Pago', type: 'text', width: '100px', sortable: false },
        { value: 'state_id', text: 'Estado', type: 'text', sortable: false },
        { value: 'scholarship', text: 'Becado', type: 'boolean', sortable: false },            
        { value: 'action', sortable: false, width: '100px' },
    ];

    get list() {
        let list = [];
        this.$store.state.enrollment.list.forEach((item, k) => {
            item.type = Util.abp.list.getItem(item.student.type_id);
            list.push(item);
        });
        return list;
    }
    get loading() {
        return this.$store.state.enrollment.loading;
    }
    get url() {
        return url;
    }
    get courseclass() {
        return this.$store.state.courseclass.editModel;
    }
    get isCanceled() {
        return this.courseclass.state_id == this.sState.CE;
    }

    getItem(state_id) {
        return Util.abp.list.getItem(state_id);
    }

    getInitials(string) {
        return Util.getInitials(string);
    }

    filterTable(value, search, item) {
        let res = search != null &&
            item.student != null &&
            //item.document_number != null &&
            ((item.student != null && item.student.description_full.toString().toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1) ||
            (item.document_number != null && item.document_number.toString().indexOf(search) !== -1) ||
            (item.type != null && item.type.name.toString().toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1))

        return res;
    }

    async authorizeEnroll(item) {
        await this.$store.dispatch({
            type: 'enrollment/authorize',
            data: {
                id: item.id,
                course_class_id: this.courseclass.id,
            }
        });
        this.$emit('save-success');
    }

    async deleteEnroll(item) {
        this.$swal({
            title: '&iquest;Est&aacute; seguro?',
            html: 'Se eliminar&aacute; esta matricula, al eliminarlo el asistente no podra volver a matricularse',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, &iexcl;Eliminar!',
            cancelButtonText: '&iexcl;No!',
            confirmButtonColor: '#FF5252', 
            showCloseButton: true,
            showLoaderOnConfirm: true
        }).then(async (result) => {
            if (result.value) {
                await this.$store.dispatch({
                    type: 'enrollment/delete',
                    data: { id: item.id }
                });

                this.$emit('save-success');

                this.$swal('Eliminado', 'Has eliminado esta matricula correctamente', 'success')
            }
        })
    }

    async rejectEnroll(item) {
        this.menus[item.id] = false
        await this.$store.dispatch({
            type: 'enrollment/delete',
            data: { id: item.id, message: this.reject_message, reject: true }
        });
        this.$emit('save-success');
    }

    async authorizeAll(item) {
        this.$swal({
            title: '&iquest;Est&aacute; seguro?',
            html: 'Se Autorizaran todas las matriculas pendientes, no se enviara el correo de confirmaci&oacuten',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, &iexcl;Autorizar!',
            cancelButtonText: '&iexcl;No!',
            confirmButtonColor: '#4CAF50',
            showCloseButton: true,
            showLoaderOnConfirm: true
        }).then(async (result) => {
            if (result.value) {
                await this.$store.dispatch({
                    type: 'enrollment/authorize',
                    data: {
                        course_class_id: this.courseclass.id,
                    }
                });
                this.$emit('save-success');

                this.$swal('Autorizados', 'Se han autorizado todas las matricula pendientes', 'success')
            }
        })            
    }

    async update(item) {
        await this.$store.dispatch({
            type: 'enrollment/update',
            data: item
        });
    }

    async getpage() {
        await this.$store.dispatch({
            type: 'enrollment/getAll',
            data: { course_class: this.courseclass.id, perPage: 2000 }
        });
    }

    viewStudent(item) {
        this.$store.commit('student/edit', item);
        this.showStuModal = !this.showStuModal;
    }

    created() {

    }

    mounted() {

    }
}