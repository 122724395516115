














































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import ModalView from '@/components/helper/modal-view.vue';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';

@Component({
    components: { ModalView },
    watch: {
        value: function (val) {
            let obj = (this as any);
            obj.modalShow = val;
            if (val) {
            }
        },
        modalShow: function (val) {
            if (!val) {
                this.$emit('input', false);
            }
        }
    }
})

export default class ImprotEnabledView extends ListBase {
    @Prop({ type: Boolean, default: false }) value: boolean;
    file: any = null;
    modalSetting: any = {
        entity: "enrollment",
        width: "95%",
        showForm: false,
        titleFull: 'Importar Asistentes',
        icon: 'mdi-upload',
        saveText: 'Importar Asistentes',
        saveIcon: 'mdi-upload',
        disabledSave: true,
        customSave: true,
        persistent: false
    };

    columns = [
        { value: 'type_id', text: 'Tipo', type: 'text', sortable: false },
        { value: 'firstname', text: 'Nombres', type: 'text', sortable: false },
        { value: 'lastname', text: 'Apellidos', type: 'text', sortable: false },
        { value: 'document', text: 'DNI', type: 'text', sortable: false },
        { value: 'email', text: 'Email', type: 'text', sortable: false },
        { value: 'phone_mobile', text: 'Telefono', type: 'text', sortable: false },
        { value: 'scholarship', text: 'Becado', type: 'text', sortable: false },
    ]

    get disabled() {
        if (this.file == null) return true;
        else return false
    }
    get loading() {
        return this.$store.state.enrollment.loading;
    }
    get list() {
        return this.$store.state.enrollment.reportList;
    };
    get totalCount() {
        return this.list.length;
    };
    get course_class() {
        return this.$store.state.courseclass.editModel;
    };

    getTypeName(id) {
        return Util.abp.list.getName(id);
    }

    async importFile() {
        this.modalSetting.disabledSave = true;
        if (this.file == null) return;

        let formData = new FormData();
        formData.append('file', this.file);
        formData.append('course_class', this.course_class.id);

        let result = await this.$store.dispatch({
            type: 'enrollment/importBecados',
            data: formData
        });

        if (result) {
            if (this.totalCount > 0) {
                this.$toast.info('Primero Revise los datos Importados, <br/>Luego Presione "Importar Becados" para completar el proceso', { timeout: 5000 });
                this.modalSetting.disabledSave = false;
            } else
                this.$toast.warning('No se encontraron registros validos', { timeout: 3000 });
        }
    }

    async save() {
        if (this.totalCount > 0) {

            let newModel = await this.$store.dispatch({
                type: 'enrollment/createBatch',
                data: {
                    items: this.list,
                    course: this.course_class.course_id,
                    course_class: this.course_class.id
                }
            });

            this.$toast.success('Los becados han sido registrados');
            this.$emit('save-success');
            this.cancel();

            return newModel;
        } else
            return false;
    }

    cancel() {
        this.file = null;
        this.$store.commit('enrollment/setReportList', []);
        this.$emit('input', false);
    }

    mounted() {
    }

    created() {
    }
}
