





















































































































































import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import AbpBase from '@/lib/abpbase'
import Util from '@/lib/util';
var intervalTimer;

@Component({
    components: {},
    watch: {
        selectedOpen: function (val, oldVal) {
            let obj = (this as any);
            if (val && !oldVal) {
                obj.setTimeElapsed();
            } else
                clearInterval(intervalTimer);
        },
    },
})
export default class EenrollmentsList extends AbpBase {
    attrs: any = { boilerplate: true, };
    focus: any = '';
    events: Array<any> = [];
    colors: Array<string> = ['light-blue lighten-1', 'green', 'indigo', 'deep-orange', 'grey darken-1'];
    type: string = 'month';
    type_text: string = 'Mes';
    selectedEvent: any = {};
    selectedElement: any = null;
    selectedOpen: boolean = false;
    timeElapsed: string = '00:00:00';
    dragEvent: any = null;
    dragStart: any = null;
    createEvent: any = null;
    createStart: any = null;
    extendOriginal: any = null;
    dragTime: any = null;
    sState: any = Util.abp.list.getCodeList('E_CLASS');
    typesList: Array<any> = [
        { id: 'month', name: 'Mes' },
        { id: 'week', name: 'Semana' },
        //{ id: 'day', name: 'Dia' },
        //{ id: '4day', name: '4 Dias' }
    ];

    columns = [
        { value: 'student.fullname', text: 'Asistente', type: 'text', sortable: false },
        { value: 'amount', text: 'Monto', type: 'text', sortable: false },
        { value: 'created_at', text: this.L('Fecha de Matricula'), type: 'text', sortable: false },
        { value: 'state_id', text: 'Estado', type: 'text', sortable: false },
    ];

    get list() {
        return this.$store.state.lesson.list;
    }
    get totalCount() {
        return this.$store.state.lesson.list.length;
    }
    get loading() {
        return this.$store.state.lesson.loading;
    }
    get loadingAction() {
        return this.$store.state.lesson.loadingAction;
    }
    get loadingClass() {
        return this.$store.state.courseclass.loading;
    }
    get loadingAttendance() {
        return this.$store.state.attendance.loading;
    }
    get attendanceLink() {
        return Util.abp.setting.get('URL_ATTENDANCE');
    }
    get markTimeout() {
        let marks_timeout = this.$store.state.courseclass.editModel.marks_timeout;
        if (marks_timeout != Util.abp.setting.get('MARKS_TIMEOUT') && marks_timeout != null) return marks_timeout;
        return Util.abp.setting.get('MARKS_TIMEOUT');
    }
    get nroMarks() {
        return this.$store.state.courseclass.editModel.schedule.nro_marks;
    }
    get isCanceled() {
        return this.courseclass.state_id == this.sState.CE;
    }
    get courseclass() {
        return this.$store.state.courseclass.editModel;
    }
    get manualAttendance():boolean {
        return Util.abp.setting.get('ENABLE_MANUAL_ATTENDACE');
    }

    getInitials(string) {
        return Util.getInitials(string);
    }

    initProcess() {
        this.$emit('init-process');
    }

    /******** EVENTOS DE CALENDARIO ********* */
    setToday() {
        this.focus = ''
    }
    prev() {
        (this.$refs.calendar as any).prev()
    }
    next() {
        (this.$refs.calendar as any).next()
    }
    viewDay({ date }) {
        this.focus = date
        this.type = 'week'
        this.type_text = 'Semana';
    }
    getEventColor(event) {
        return event.color
    }
    updateRange({ start, end }) {
        const events = [];

        if (this.totalCount > 0) {
            this.list.forEach(item => {
                const first = new Date(`${item.lesson_date.substr(0, 10)}T${item.start_hour}:00`);
                const second = new Date(`${item.lesson_date.substr(0, 10)}T${item.end_hour}:00`);
                let state = Util.abp.list.getItem(item.state_id);
                events.push({
                    name: (item.teacher == null ? '' : item.teacher.firstname) + ' - ' + state.name,
                    start: first,
                    end: second,
                    color: this.colors[state.value - 1],
                    lesson_date: item.lesson_date,
                    start_hour: item.start_hour,
                    end_hour: item.end_hour,
                    nro_attendees: item.nro_attendees,
                    capacity: item.capacity,
                    id: item.id,
                    opened: item.opened,
                    closed: item.closed,
                    canceled: item.canceled,
                    link: item.link,
                    last_open: item.last_open,
                    mark_nro: item.mark_nro,
                    timed: true,
                });
            });
        }
        
        this.events = events;
    }
    showEvent({ nativeEvent, event }) {
        const open = () => {
            this.selectedEvent = event
            this.selectedElement = nativeEvent.target
            setTimeout(() => {
                this.selectedOpen = true
            }, 10)
        }

        if (this.selectedOpen) {
            this.selectedOpen = false
            setTimeout(open, 10)
        } else {
            open()
        }

        nativeEvent.stopPropagation()
    }
    async openLesson(item) {
        //if (item.link == '' || item.link == null) {
        //    Util.abp.notify.error(this, 'Ingrese la URL para abrir la clase');
        //    return;
        //}

        let model = await this.$store.dispatch({
            type: 'lesson/open',
            data: {
                id: item.id,
                link: item.link
            }
        });
        this.selectedOpen = false;

        let state = Util.abp.list.getItem(model.state_id);
        item.color = this.colors[state.value - 1];
        item.opened = model.opened;
        item.closed = model.closed;
        item.canceled = model.canceled;            
        item.last_open = model.last_open;   
        item.mark_nro = model.mark_nro;   
        this.setTimeElapsed();
        //await this.getLessons();
        //(this.$refs.calendar as any).checkChange();
    }
    async stopLesson(item) {
        let model = await this.$store.dispatch({
            type: 'lesson/stop',
            data: {
                id: item.id
            }
        });

        item.opened = model.opened;
        clearInterval(intervalTimer);
    }
    async closeLesson(item) {
        let model = await this.$store.dispatch({
            type: 'lesson/close',
            data: { id: item.id }
        });
        this.selectedOpen = false;

        let state = Util.abp.list.getItem(model.state_id);
        item.color = this.colors[state.value - 1];
        item.opened = model.opened;
        item.closed = model.closed;
        item.canceled = model.canceled;
        //await this.getLessons();
        //(this.$refs.calendar as any).checkChange();
    }
    async cancelLesson(item) {
        let model = await this.$store.dispatch({
            type: 'lesson/cancel',
            data: { id: item.id }
        });
        this.selectedOpen = false;

        let state = Util.abp.list.getItem(model.state_id);
        item.color = this.colors[state.value - 1];
        item.opened = model.opened;
        item.closed = model.closed;
        item.canceled = model.canceled;
        //await this.getLessons();
        //(this.$refs.calendar as any).checkChange();
    }
    async attendLesson(item) {
        let model = await this.$store.dispatch({
            type: 'attendance/createBatch',
            data: { id: item.id }
        });
        this.selectedOpen = false;
        await this.getLessons();
    }
    setTimeElapsed() {
        if (this.markTimeout == 0) return;
        clearInterval(intervalTimer);
        let vm = (this as any);

        //this.startTime = vm.$moment();
        intervalTimer = setInterval(() => {

            this.events.forEach((item, k) => {
                if (item.opened && item.last_open != null && item.id == this.selectedEvent.id) {
                    var endTime = vm.$moment();
                    // calculate total duration
                    var duration = vm.$moment.duration(endTime.diff(item.last_open));

                    let minutes = this.markTimeout - duration.asMinutes();     

                    if (minutes < 0) {
                        clearInterval(intervalTimer);
                        this.stopLesson(this.selectedEvent);
                    } else {
                        this.timeElapsed = Util.formatMintues(minutes);
                        //vm.elapsedTimes[k] = this.timeElapsed;
                    }
                }
            });
        }, 1000);
    }
    startDrag({ event, timed }) {
        if (event && timed) {
            this.dragEvent = event
            this.dragTime = null
            this.extendOriginal = null
        }
    }
    startTime(tms) {
        const mouse = this.toTime(tms)

        if (this.dragEvent && this.dragTime === null) {
            const start = this.dragEvent.start

            this.dragTime = mouse - start;
        } else {
            //this.createStart = this.roundTime(mouse)
            //this.createEvent = {
            //    name: `Event #${this.events.length}`,
            //    color: this.rndElement(this.colors),
            //    start: this.createStart,
            //    end: this.createStart,
            //    timed: true,
            //}

            //this.events.push(this.createEvent)
        }
    }
    extendBottom(event) {
        this.createEvent = event
        this.createStart = event.start
        this.extendOriginal = event.end
    }
    mouseMove(tms) {
        const mouse = this.toTime(tms)
        
        if (this.dragEvent && this.dragTime !== null &&
                !this.dragEvent.closed &&
                !this.dragEvent.canceled) {
            const start = this.dragEvent.start
            const end = this.dragEvent.end
            const duration = end - start
            const newStartTime = mouse - this.dragTime
            const newStart = this.roundTime(newStartTime)
            const newEnd = newStart + duration

            this.dragEvent.start = newStart
            this.dragEvent.end = newEnd
        } else if (this.createEvent && this.createStart !== null) {
            //const mouseRounded = this.roundTime(mouse, false)
            //const min = Math.min(mouseRounded, this.createStart)
            //const max = Math.max(mouseRounded, this.createStart)

            //this.createEvent.start = min
            //this.createEvent.end = max
        }
    }
    async endDrag() {
        const start = new Date(this.dragEvent.start);
        const end = new Date(this.dragEvent.end);
        let start_hour = start.toLocaleTimeString().substr(0,5);
        let end_hour = end.toLocaleTimeString().substr(0, 5);
        let lesson_date = start.toISOString().substr(0, 10);

        if ((start_hour != this.dragEvent.start_hour ||
            end_hour != this.dragEvent.end_hour ||
            lesson_date != this.dragEvent.lesson_date.substr(0, 10)) &&
            !this.dragEvent.closed &&
            !this.dragEvent.canceled)
        {
            this.dragEvent.start_hour = start_hour;
            this.dragEvent.end_hour = end_hour;
            this.dragEvent.lesson_date = lesson_date;

            //console.log(this.dragEvent);

            await this.$store.dispatch({
                type: 'lesson/update',
                data: {
                    id: this.dragEvent.id,
                    start_hour: this.dragEvent.start_hour,
                    end_hour: this.dragEvent.end_hour,
                    lesson_date: this.dragEvent.lesson_date,
                }
            });
        }

        this.dragTime = null
        this.dragEvent = null
        this.createEvent = null
        this.createStart = null
        this.extendOriginal = null
    }
    cancelDrag() {
        if (this.createEvent) {
            if (this.extendOriginal) {
                this.createEvent.end = this.extendOriginal
            } else {
                const i = this.events.indexOf(this.createEvent)
                if (i !== -1) {
                    this.events.splice(i, 1)
                }
            }
        }

        this.createEvent = null
        this.createStart = null
        this.dragTime = null
        this.dragEvent = null
    }
    roundTime(time, down = true) {
        const roundTo = 15 // minutes
        const roundDownTime = roundTo * 60 * 1000

        return down
            ? time - time % roundDownTime
            : time + (roundDownTime - (time % roundDownTime))
    }
    toTime(tms) {
        return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
    }

    /******** CONTRUCTOR ********* */
    async getLessons() {
        await this.$store.dispatch({
            type: 'lesson/getAll',
            data: { course_class: this.$store.state.courseclass.editModel.id }
        });
    }

    created() {

    }

    mounted() {
        if ((this.$refs.calendar as any) != undefined)
            (this.$refs.calendar as any).checkChange();
    }
}