





























import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import FormBase from '@/components/helper/form-base.vue';
import ModalView from '@/components/helper/modal-view.vue';
import Util from '@/lib/util';
import url from '@/lib/url';
import ListBase from '@/lib/listbase';
import Enrollment from '@/store/entities/courses/enrollment';
import StudentForm from '@/views/students/student/student-form.vue';
import ImportBecado from './import-becado.vue';

@Component({
    components: { FormBase, ModalView, StudentForm, ImportBecado },
    watch: {
        value: function (val) {
            let obj = (this as any);
            obj.modalShow = val;
            if (val) {
                obj.enrollment = Util.extend(true, {}, this.$store.state.enrollment.editModel);
                obj.initForm();
            }
        },
        modalShow: function (val) {
            if (!val) {
                this.$emit('input', false);
            }
        }
    }
})

export default class EnrollmentForm extends ListBase {
    @Prop({ type: Boolean, default: false }) value: boolean;
    enrollment: Enrollment = new Enrollment();
    modalShow: boolean = false;
    showStudent: boolean = false;
    showBecado: boolean = false;
    modalSetting: any = {
        entity: "enrollment",
        titleFull: 'Matricular Asistente',
        saveText: 'Matricular',
        saveIcon: 'control_point',
        width:'700px',
        showForm: false
    };

    items = [
        this.gItem({ type: 'object', prop: 'student', label: 'Asistente', autocomplete: true, itemText: 'description_full', hint: '', persistentHint: true, returnObject: true, required: true, outerIcon: 'add_circle', appendEvent: this.newStudent, onchange: this.changeStudent, size: 8 }),
        this.gItem({ type: 'switch', prop: 'scholarship', label: 'Becado?', size: 4, onchange: this.changeSchool }),
        this.gItem({ type: 'text', prop: 'document_number', label: 'Nro de Boleta Emitida por Caja', size: 8 }),
        this.gItem({ type: 'number', prop: 'amount', label: 'Monto de Matricula', prefix: 'S/', size: 4, hint: '', persistentHint: true }),
        this.gItem({ type: 'textarea', prop: 'notes', label: 'Notas', size: 12, rows: 3 }),
    ];

    /***** computed methods *****/
    get loading() {
        return this.$store.state.enrollment.loading;
    };

    get courseclass() {
        return this.$store.state.courseclass.editModel;
    };

    /***** fields events *****/
    getInitials(string) {
        return Util.getInitials(string);
    }

    changeStudent(item) {
        this.enrollment.student_id = item.id;
        let type = Util.getTypeValueById(item.type_id);
        //let fee = Util.getEntityByParam(this, 'fee', 'student_type_id', item.type_id);
        let fee_id = 0;
        if (type.code == 'A') {
            fee_id = (item.age > 60 ? this.courseclass.course.fee_lawyer_old_id : this.courseclass.course.fee_lawyer_id);
        }                
        else
            fee_id = this.courseclass.course.fee_public_id;

        let fee = Util.getEntity(this, 'fee', fee_id);
        this.setItem(this.items, 'student', 'hint', type.name);

        if (fee != null) {
            this.setItem(this.items, 'amount', 'hint', fee.name);
            this.enrollment.amount = fee.amount;
        }
    }

    changeSchool(val) {
        this.setItem(this.items, 'document_number', 'visible', !val);
        this.setItem(this.items, 'amount', 'visible', !val);
    }

    newStudent() {
        this.$store.commit('student/new');
        this.showStudent = !this.showStudent;
    }

    /***** initial methods *****/
    async initForm() {
        await this.$store.dispatch({type: 'fee/getAllActive'});
    }

    async save() {
        if ((this.$refs.entityForm1 as any).validate()) {
            let model = Util.extend(true, {}, this.enrollment);
            model.admin_create = true;
            delete model.student;
            await this.$store.dispatch({
                type: 'enrollment/create',
                data: model
            });
            (this.$refs.entityForm1 as any).reset();
            this.$emit('input', false);
            this.$emit('save-success');
            Util.abp.notify.success(this, 'El asistente ha sido matriculado');
        }
    }

    afterCreateBecados() {
        this.$emit('input', false);
        this.$emit('save-success');
    }

    cancel() {
        this.$emit('input', false);
    }

    mounted() {
    }

    created() {
    }
}
