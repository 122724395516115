














































































































































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import FormBase from '@/components/helper/form-base.vue';
import VWidget from '@/components/VWidget.vue';
import PageRequest from '@/store/entities/page-request';
import Util from '@/lib/util';
import url from '@/lib/url';
import ListBase from '@/lib/listbase';
import CourseClass from '@/store/entities/courses/courseclass';
import Course from '@/store/entities/courses/course';
import History from './history.vue';
import Enrollments from './enrollments.vue';
import Lessons from './lessons.vue';
import AddEnroll from './add-enroll.vue';
import Teacher from '@/store/entities/courses/teacher';

class PageClassRequest extends PageRequest {
    course_class: number = null;
}

@Component({
    components: { FormBase, VWidget, History, Enrollments, Lessons, AddEnroll },
})

export default class VacancyForm extends ListBase {
    @Prop({ type: Boolean, default: false }) value: boolean;
    courseclass: CourseClass = new CourseClass();
    pagerequest: PageClassRequest = new PageClassRequest();
    showEnroll: boolean = false;
    modalShow: boolean = true;
    urlPhoto: string = url;
    sState: any = Util.abp.list.getCodeList('E_CLASS');
    actions: Array<any> = [
        { title: 'Terminar', icon: 'block', event: this.finish },
        { title: 'Cancelar', icon: 'close', event: this.close },
    ];

    filterItems = [
        this.gItem({ type: 'object', prop: 'course_class', label: 'Clase', size: 8, hideDetails: true, onchange: this.loadData }),
        this.gItem({ type: 'spacer', size: 4 }),
    ]

    /***** computed methods *****/
    get loading() {
        return this.$store.state.courseclass.loading;
    };

    get getLoading() {
        return this.$store.state.courseclass.getLoading;
    };        

    get current_state() {
        if (this.courseclass == undefined || this.courseclass.state_id == undefined) return {};
        return Util.abp.list.getItem(this.courseclass.state_id);
    }

    get days() {
        let list = [];
        let week = this.gCommonList('week_days');
        this.courseclass['schedule'].days_list.forEach(id => {
            list.push(week[id-1].name);
        });
        return list;
    }

    get enrollProgress() {
        return this.courseclass.enrolled_students * 100 / this.courseclass.capacity;
    }

    set enrollProgress(value) {
        
    }

    get urlEnroll() {
        return Util.abp.setting.get('URL_ENROLLMENT');
    }

    get isCanceled() {
        return this.courseclass.state_id == this.sState.CE;
    }
    

    /***** fields events *****/
    getInitials(string) {
        return Util.getInitials(string);
    }

    changeTab(item) {
        if (item == 1) {
            this.getEnrollments();
        } else if (item == 2) {
            this.getLessons();
        } else if (item == 3) {
            this.getHistory();
        }
    }

    addEnroll() {
        this.$store.commit('enrollment/edit', {
            course_id: this.courseclass.course_id,
            course_class_id: this.courseclass.id,
            amount: this.courseclass['fee'].amount
        });
        this.showEnroll = !this.showEnroll;
    }

    async stopEnroll() {
        this.$swal({
            title: '&iquest;Cerrar Matr&iacute;culas?',
            html: 'Esta Seguro, ya no se podran regitrar mas asistentes, una ves cerrada las matr&iacute;culas',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, &iexcl;Cerrar!',
            cancelButtonText: '&iexcl;Aun No!',
            //cancelButtonColor: '#FF5252',
            showCloseButton: true,
            showLoaderOnConfirm: true
        }).then(async (result) => {
            if (result.value) {
                await this.$store.dispatch({
                    type: 'courseclass/stopEnroll',
                    data: { id: this.courseclass.id }
                });
                this.$emit('save-success');
                this.courseclass.enrolling = false;
            }
        });            
    }

    async initProcess() {
        let model = await this.$store.dispatch({
            type: 'courseclass/initProcess',
            data: { id: this.courseclass.id }
        });
        this.$emit('save-success');
        this.$store.commit('courseclass/view', model);
        this.courseclass = Util.extend(true, {}, this.$store.state.courseclass.editModel);
        await this.getLessons();
    }

    finish() {
        this.$swal({
            title: '&iquest;Terminar la Clase?',
            html: 'Al finalizar, se consolidaran las asistencias, y el curso estara disponible para emitir su certificado',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, &iexcl;Finalizar!',
            cancelButtonText: '&iexcl;No!',
            cancelButtonColor: '#FF5252',
            showCloseButton: true,
            showLoaderOnConfirm: true
        }).then(async (result) => {
            if (result.value) {
                let model = await this.$store.dispatch({
                    type: 'courseclass/finish',
                    data: { id: this.courseclass.id }
                });

                this.$swal('Finalizado', 'La clase ha finalizado, ya puede emitir los certificados', 'success');

                this.$emit('save-success');
                this.$store.commit('courseclass/view', model);
                this.courseclass = Util.extend(true, {}, this.$store.state.courseclass.editModel);
            }
        })
    }

    close() {
        this.$swal({
            title: '&iquest;Anular la Clase?',
            html: 'Esta seguro de anular y cerrar la clase, esta clase ya no estara disponible, esta accion no se puede revertir',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, &iexcl;Anular!',
            cancelButtonText: '&iexcl;No!',
            cancelButtonColor: '#FF5252',
            showCloseButton: true,
            showLoaderOnConfirm: true
        }).then(async (result) => {
            if (result.value) {
                let model = await this.$store.dispatch({
                    type: 'courseclass/delete',
                    data: { id: this.courseclass.id }
                });

                this.$swal('Clase Cancelada', 'La clase ha sido cancelada', 'success');

                this.$emit('save-success');
                this.$store.commit('courseclass/view', model);
                this.courseclass = Util.extend(true, {}, this.$store.state.courseclass.editModel);
            }
        })
    }

    async manualCertificate() {
        let model = await this.$store.dispatch({
            type: 'courseclass/update',
            data: {
                id: this.courseclass.id,
                state_id: this.courseclass.state_id,
                certified: true
            }
        });

        this.$emit('save-success');
        this.$store.commit('courseclass/view', model);
        this.courseclass = Util.extend(true, {}, this.$store.state.courseclass.editModel);
    }

    /***** RETORNO DE MODAL *****/
    async getHistory() {
        await this.$store.dispatch({
            type: 'courseclass/getHistory'
        });
    }

    async getEnrollments() {
        await this.$store.dispatch({
            type: 'enrollment/getAll',
            data: { course_class: this.courseclass.id, perPage: 2000 }
        });
    }

    async getLessons() {
        await this.$store.dispatch({
            type: 'lesson/getAll',
            data: { course_class: this.courseclass.id }
        });
    }

    async afterAuthorize() {
        await this.getEnrollments();
        let totals = 0;
        this.$store.state.enrollment.list.forEach(item => {
            totals += (item.authorized ? 1 : 0);
        });

        this.courseclass.enrolled_students = totals;
    }


    /***** FUNCIONES DE INICIO *****/
    async loadData(id) {
        let model = await this.$store.dispatch({
            type: 'courseclass/get',
            id: id
        });
        this.$store.commit('courseclass/view', model);
        this.courseclass = Util.extend(true, {}, this.$store.state.courseclass.editModel);
    }
    
    async mounted() {
        if (this.$route.params.id != undefined) {
            if (this.$store.state.courseclass.editModel.id != this.$route.params.id) {
                await this.loadData(this.$route.params.id);
            }
            this.courseclass = Util.extend(true, {}, this.$store.state.courseclass.editModel);
        }

        this.$store.dispatch({
            type: 'courseclass/getAllActive'
        });
    }

    async created() {
        this.courseclass.course = new Course();
        this.courseclass.teacher = new Teacher();
    }
}
