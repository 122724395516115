var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal-view',{ref:"form",attrs:{"mainSetting":_vm.modalSetting},on:{"on-cancel":_vm.cancel,"on-ok":_vm.save},model:{value:(_vm.modalShow),callback:function ($$v) {_vm.modalShow=$$v},expression:"modalShow"}},[_c('div',{attrs:{"slot":"modal-content-side1"},slot:"modal-content-side1"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"7"}},[_c('v-alert',{staticClass:"tips-alert mb-0",attrs:{"text":"","dense":"","outlined":"","color":"error","border":"left","icon":"mdi-fire"}},[_c('ul',[_c('li',[_vm._v("El archivo de Excel debe tener las columnas indicadas a continuación.")]),_c('li',[_vm._v("El Tipo puede ser (E)studiante/Bachiller, (A)gremiado o (P)rofesional.")]),_c('li',[_vm._v("En la columna BECADO colocar \"SI\" o \"NO\".")])])])],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"5"}},[_c('v-file-input',{attrs:{"counter":"","dense":"","label":"Archivo","placeholder":"Seleccione un Archivo","prepend-icon":"mdi-paperclip","outlined":"","accept":".XLSX, .XLS, .XLSM, .CSV","show-size":1000},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
var text = ref.text;
return [_c('v-chip',{attrs:{"color":"primary accent-4","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(text)+" ")])]}}]),model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('v-btn',{attrs:{"depressed":"","block":"","color":"primary","disabled":_vm.disabled,"loading":_vm.loading},on:{"click":_vm.importFile}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-upload")]),_vm._v(" Importar")],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0 striped products-details",attrs:{"headers":_vm.columns,"items":_vm.list,"items-per-page":10,"loading":_vm.loading,"loading-text":"Cargando... Por favor Espere","dense":"","footer-props":{
                                      itemsPerPageOptions: [10,20,50,100],
                                      itemsPerPageText: 'Registros por Pagina',
                                      showFirstLastPage: true,
                                    }},scopedSlots:_vm._u([{key:"item.type_id",fn:function(ref){
                                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTypeName(item.type_id))+" ")]}},{key:"item.scholarship",fn:function(ref){
                                    var item = ref.item;
return [(item.scholarship)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" check ")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" close ")])]}},{key:"no-data",fn:function(){return [_c('v-row',{staticClass:"d-flex flex-wrap text-center justify-space-around",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-btn',{staticStyle:{"height":"100px"},attrs:{"icon":"","large":""}},[_c('v-icon',{attrs:{"size":"100px","color":"success"}},[_vm._v("mdi-upload")])],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('h4',[_vm._v("Importe su archivo de Asistentes!")])]),_c('v-col',{staticStyle:{"font-size":"16px"},attrs:{"cols":"12","lg":"12"}})],1)]},proxy:true}])})],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }