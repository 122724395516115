var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-text',{staticClass:"px-0"},[_c('v-data-table',{attrs:{"headers":_vm.columns,"items":_vm.list,"loading":_vm.loading,"search":_vm.search,"dense":"","custom-filter":_vm.filterTable},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","lg":"10"}},[_c('v-text-field',{staticClass:"mx-4 pt-2",attrs:{"dense":"","hide-details":"","label":"Buscar Asistentes por DNI, Nombres o Tipo de Asistente"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","lg":"2"}},[_c('v-spacer'),(!_vm.courseclass.finished && !_vm.isCanceled)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-1 mx-2",attrs:{"icon":"","color":"success"},on:{"click":function($event){return _vm.authorizeAll()}}},on),[_c('v-icon',[_vm._v("done_all")])],1)]}}],null,false,3777026450)},[_c('span',[_vm._v(_vm._s(_vm.L('Autorizar Pendientes')))])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-1 mx-2",attrs:{"icon":""},on:{"click":_vm.getpage}},on),[_c('v-icon',[_vm._v("refresh")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.L('Actualizar')))])])],1)],1)]},proxy:true},(_vm.loading)?{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"table-row-divider@5"}})]},proxy:true}:null,{key:"item.student",fn:function(ref){
var item = ref.item;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{staticClass:"pl-0"},[_c('v-list-item-avatar',[_c('v-avatar',{attrs:{"color":"primary"}},[(!hover)?_c('span',{staticClass:"white--text body-1"},[_vm._v(_vm._s(_vm.getInitials(item.student.fullname)))]):_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){return _vm.viewStudent(item.student)}}},[_vm._v("visibility")])],1)],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"body-2",domProps:{"innerHTML":_vm._s(item.student.fullname)}}),_c('v-list-item-subtitle',{staticClass:"caption"},[_c('b',[_vm._v("DNI:")]),_vm._v(" "+_vm._s(item.student.document)+" "),_c('b',{staticClass:"ml-2"},[_vm._v("Matrícula:")]),_vm._v(_vm._s(item.created_at.substr(0,16)))]),_c('v-list-item-subtitle',{staticClass:"caption"},[_c('b',[_vm._v("Tipo:")]),_vm._v(" "+_vm._s(item.type.name)+" "),(item.student.phone_mobile != null && item.student.phone_mobile != '')?_c('span',[_c('b',{staticClass:"ml-2"},[_vm._v("Teléfono:")]),_vm._v(" "+_vm._s(item.student.phone_mobile))]):_vm._e()])],1)],1)]}}],null,true)})]}},{key:"item.state_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{style:({ backgroundColor: _vm.getItem(item.state_id).extra }),attrs:{"small":"","dark":""}},[_vm._v(_vm._s(_vm.getItem(item.state_id).name))])]}},(_vm.courseclass.in_process)?{key:"item.ranking",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"mt-2",attrs:{"label":"","dense":"","hide-details":""},on:{"change":function($event){return _vm.update(item)}},model:{value:(item.ranking),callback:function ($$v) {_vm.$set(item, "ranking", $$v)},expression:"item.ranking"}})]}}:null,{key:"item.document_number",fn:function(ref){
var item = ref.item;
return [(item.document_url != null)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","color":"success","text":"","href":_vm.url + item.document_url,"target":"_blank"}},on),[_vm._v(_vm._s(item.document_number))])]}}],null,true)},[(item.notes != null)?_c('span',[_vm._v(_vm._s(item.notes))]):_vm._e()]):_vm._e()]}},{key:"item.scholarship",fn:function(ref){
var item = ref.item;
return [(item.scholarship)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" check ")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" close ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.authorized)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"error","loading":_vm.loading,"text":"","icon":"","small":""},on:{"click":function($event){return _vm.deleteEnroll(item)}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_vm._v(" Eliminar ")]):_vm._e(),(!item.authorized)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"success","loading":_vm.loading,"text":"","icon":"","small":""},on:{"click":function($event){return _vm.authorizeEnroll(item)}}},on),[_c('v-icon',[_vm._v("thumb_up")])],1)]}}],null,true)},[_vm._v(" Autorizar ")]):_vm._e(),(!item.authorized)?_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"color":"error","loading":_vm.loading,"text":"","icon":"","small":""}},on),[_c('v-icon',[_vm._v("thumb_down")])],1)]}}],null,true),model:{value:(_vm.menus[item.id]),callback:function ($$v) {_vm.$set(_vm.menus, item.id, $$v)},expression:"menus[item.id]"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"error","dense":"","flat":"","dark":""}},[_c('v-toolbar-title',[_c('h5',{staticClass:"white--text"},[_vm._v("Rechazar Matrícula")])])],1),_c('v-card-text',[_c('v-text-field',{attrs:{"autocomplete":"none","label":"Ingrese el motivo del rechazo","outlined":"","dense":""},model:{value:(_vm.reject_message),callback:function ($$v) {_vm.reject_message=$$v},expression:"reject_message"}})],1),_c('v-card-actions',[_c('v-divider'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.menus[item.id] = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"error","text":"","loading":_vm.loading},on:{"click":function($event){return _vm.rejectEnroll(item)}}},[_vm._v(" Eliminar Matrícula ")])],1)],1)],1):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-row',{staticClass:"d-flex flex-wrap text-center justify-space-around",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-icon',{attrs:{"size":"100px","color":"#F8BB86"}},[_vm._v("supervisor_account")])],1),_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('h4',[_vm._v("Nadie se ha Matriculado")])]),_c('span',{staticClass:"caption mb-4"},[_vm._v(" Parece que todavía no hay ningún asistente matriculado "),_c('br'),_vm._v(" Por favor, inténtalo más tarde. Los asistentes nuevos aparecerán próximamente ")])],1)]},proxy:true}],null,true)}),_c('student-form',{model:{value:(_vm.showStuModal),callback:function ($$v) {_vm.showStuModal=$$v},expression:"showStuModal"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }